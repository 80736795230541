
#app
    input
       display none
    #header-block
        background-color #F0F0F0
        color #353535
        z-index: 200;
        .logoBlock
            grid-area: L;
            align-items     end
            padding 0.5em 0
            padding-bottom  10px
            justify-items   start
            background      none/**/
            background-color none
            .logo
                padding-top        5.5em /*2.5em/**/
                background-size    contain
                background-repeat  no-repeat
                width              100%
                /*min-width          240px/**/
                font-size          10px
                background-image   url("../assets/img/logo.svg")
        a
            color #353535
            width: 100%;
        menu
            grid-area: M;
            align-items: center;
            justify-items: center;
            background none
            background-color #F0F0F0
            padding 0
            li
                list-decoration none
                display inline-block
                background-color #F0F0F0
                a
                    border-bottom solid 1px #F0F0F0
                    &.gold
                        border solid 1px #CDAD6B
                        color #353535
                        padding 0.5em 1em
                    &.router-link-active
                        border solid 1px #353535
                        background-color #F0F0F0
                        color  #353535
                    &:hover
                        border-bottom solid 1px #353535
        .phoneBlock
            grid-area               P
            background              none
            align-items             end
            padding-bottom          10px
            justify-items           end
            .phone
                margin              0 0.5em
                display             inline-block
            .pic
                width               1.2em
                background-size     99.9%
                line-height         1em
                font-size           21px
                background-repeat   no-repeat
                background-position center center
                display             inline-block
                &.phone
                     background-image url("../assets/img/picto/phone.svg")
                &.menu
                     display none
                     background-image url("../assets/img/picto/menu-black.svg")
            input:checked
                +.pic.menu
                     background-image url("../assets/img/picto/close-red.svg")

body
       #app
           #header-block
                position: sticky;
                grid-template-areas  "S L L L L L P P P P P P P E"
                min-height           70px
                menu.blockItem
                       display: none
                input:checked
                    +menu.blockItem
                        display          grid
                        column-gap       20px
                        justify-content  center
                        position         absolute
                        z-index          200
                        background-color #F0F0F0
                        margin-top       70px
                        right            0
                        padding          1em
                        padding-right: 150px
                        li
                            display      grid
                            a
                                padding 0.5em
                .phoneBlock
                    .pic
                        &.menu
                           display inline-block
                    .phone
                         display: none
@media only screen and (min-width: 500px)
   body
       #app
           #header-block
                grid-template-areas  "S L L L L L P P P P P P P E"
                input:checked
                    +menu.blockItem
                         li.phoneMob
                              display none
                .phoneBlock
                     .phone
                         display inline-block
@media only screen and (min-width: 768px)
   body
       #app
           #header-block
                grid-template-areas  "S L L L P P P  P P P P P P E"
                .logoBlock
                     padding 0.5em 0
                     align-items   center
                     .logo
                          padding-top        5.5em
                          /*font-size          0.5em/**/
                .phoneBlock
                      align-items   center
                      padding 0
@media only screen and (min-width: 1024px)
   body
       #app
           #header-block
                grid-template-areas  "S L M M M M M M M M P P P E"
                menu.blockItem
                    display          grid
                    column-gap       20px
                    justify-content  end
                    background-color #F0F0F0
                    li
                        padding      0 0.1em;
                        a
                            padding  0.5em
                        &.phoneMob
                            display  none

                .phoneBlock
                    .pic.menu
                        display none
@media only screen and (min-width: 1406px)
   body
       #app
           #header-block
                grid-template-areas  "S L M M M M M M M M P P P E"


@media only screen and (min-width: 1696px)
   body
       #app
           #header-block
                grid-template-areas  "S L L M M M M M M M P P P E"
                .logoBlock
                     .logo
                          font-size   0.8em
                menu.blockItem
                    justify-content  center
