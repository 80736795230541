
#app
    #content-block
        grid-template-areas "O S S S S S S S S S S S S E " " O H H H H H H H H H H H H E ";
        align-content: start;
    .content
        grid-area H
        #map
            height 20em
            overflow: hidden
