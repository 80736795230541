@import "./assets/fonts/roboto/100/style.css";
@import "./assets/fonts/roboto/300/style.css";
@import "./assets/fonts/roboto/700/style.css";
.images010 {
  background-image: url("./assets/img/images/010.webp");
}
.images011 {
  background-image: url("./assets/img/images/011.webp");
}
.images012 {
  background-image: url("./assets/img/images/012.webp");
}
.images013 {
  background-image: url("./assets/img/images/013.webp");
}
.images014 {
  background-image: url("./assets/img/images/014.webp");
}
.images015 {
  background-image: url("./assets/img/images/015.webp");
}
.images001 {
  background-image: url("./assets/img/images/001.webp");
}
.images002 {
  background-image: url("./assets/img/images/002.webp");
}
.images003 {
  background-image: url("./assets/img/images/003.webp");
}
.images000 {
  background-image: url("./assets/img/images/000.webp");
}
.pictoL {
  background-image: url("./assets/img/picto/l.svg");
}
.pictoB {
  background-image: url("./assets/img/picto/b.svg");
}
.pictoK {
  background-image: url("./assets/img/picto/k.svg");
}
.pictoR {
  background-image: url("./assets/img/picto/r.svg");
}
.pictoP {
  background-image: url("./assets/img/picto/p.svg");
}
.image-block {
  background-size: contain;
  width: 33%;
  padding-top: 33%;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
}
.image-block.block100 {
  width: 100%;
}
.center {
  text-align: center;
  text-align-last: center;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  font-family: 'Roboto', sans-serif;
  color: #353535;
  font-size: 17px;
}
.block {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 3em;
  display: grid;
  column-gap: 20px;
  justify-content: center;
}
.blockItem {
  display: grid;
  background-color: #ff0;
}
a,
a:active,
a:visited {
  text-decoration: none;
  color: #353535;
}
#app {
  height: 100%;
  width: 100%;
}
#app #content-block {
  min-height: 30em;
}
body .block {
  grid-template-columns: 0px repeat(12, 1fr) 0px;
}
body .block [item="3"],
body .block [item="4"],
body .block [item="5"],
body .block [item="6"],
body .block [item="7"],
body .block [item="8"],
body .block [item="9"],
body .block [item="10"],
body .block [item="11"],
body .block [item="12"] {
  display: grid;
}
@media only screen and (min-width: 1024px) {
  body .block {
    grid-template-columns: 1fr repeat(12, 60px) 1fr;
/*grid-template-columns: repeat(12,1fr);
           [item="5"],[item="6"]
               display grid/**/
  }
}
.hide {
  display: none;
}
/*# sourceMappingURL=src/App.css.map */