#app input {
  display: none;
}
#app #header-block {
  background-color: #f0f0f0;
  color: #353535;
  z-index: 200;
}
#app #header-block .logoBlock {
  grid-area: L;
  align-items: end;
  padding: 0.5em 0;
  padding-bottom: 10px;
  justify-items: start;
  background: none /**/;
  background-color: none;
}
#app #header-block .logoBlock .logo {
  padding-top: 5.5em /*2.5em/**/;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
/*min-width          240px/**/
  font-size: 10px;
  background-image: url("../assets/img/logo.svg");
}
#app #header-block a {
  color: #353535;
  width: 100%;
}
#app #header-block menu {
  grid-area: M;
  align-items: center;
  justify-items: center;
  background: none;
  background-color: #f0f0f0;
  padding: 0;
}
#app #header-block menu li {
  list-decoration: none;
  display: inline-block;
  background-color: #f0f0f0;
}
#app #header-block menu li a {
  border-bottom: solid 1px #f0f0f0;
}
#app #header-block menu li a.gold {
  border: solid 1px #cdad6b;
  color: #353535;
  padding: 0.5em 1em;
}
#app #header-block menu li a.router-link-active {
  border: solid 1px #353535;
  background-color: #f0f0f0;
  color: #353535;
}
#app #header-block menu li a:hover {
  border-bottom: solid 1px #353535;
}
#app #header-block .phoneBlock {
  grid-area: P;
  background: none;
  align-items: end;
  padding-bottom: 10px;
  justify-items: end;
}
#app #header-block .phoneBlock .phone {
  margin: 0 0.5em;
  display: inline-block;
}
#app #header-block .phoneBlock .pic {
  width: 1.2em;
  background-size: 99.9%;
  line-height: 1em;
  font-size: 21px;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
}
#app #header-block .phoneBlock .pic.phone {
  background-image: url("../assets/img/picto/phone.svg");
}
#app #header-block .phoneBlock .pic.menu {
  display: none;
  background-image: url("../assets/img/picto/menu-black.svg");
}
#app #header-block .phoneBlock input:checked +.pic.menu {
  background-image: url("../assets/img/picto/close-red.svg");
}
body #app #header-block {
  position: sticky;
  grid-template-areas: "S L L L L L P P P P P P P E";
  min-height: 70px;
}
body #app #header-block menu.blockItem {
  display: none;
}
body #app #header-block input:checked +menu.blockItem {
  display: grid;
  column-gap: 20px;
  justify-content: center;
  position: absolute;
  z-index: 200;
  background-color: #f0f0f0;
  margin-top: 70px;
  right: 0;
  padding: 1em;
  padding-right: 150px;
}
body #app #header-block input:checked +menu.blockItem li {
  display: grid;
}
body #app #header-block input:checked +menu.blockItem li a {
  padding: 0.5em;
}
body #app #header-block .phoneBlock .pic.menu {
  display: inline-block;
}
body #app #header-block .phoneBlock .phone {
  display: none;
}
@media only screen and (min-width: 500px) {
  body #app #header-block {
    grid-template-areas: "S L L L L L P P P P P P P E";
  }
  body #app #header-block input:checked +menu.blockItem li.phoneMob {
    display: none;
  }
  body #app #header-block .phoneBlock .phone {
    display: inline-block;
  }
}
@media only screen and (min-width: 768px) {
  body #app #header-block {
    grid-template-areas: "S L L L P P P  P P P P P P E";
  }
  body #app #header-block .logoBlock {
    padding: 0.5em 0;
    align-items: center;
  }
  body #app #header-block .logoBlock .logo {
    padding-top: 5.5em;
/*font-size          0.5em/**/
  }
  body #app #header-block .phoneBlock {
    align-items: center;
    padding: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body #app #header-block {
    grid-template-areas: "S L M M M M M M M M P P P E";
  }
  body #app #header-block menu.blockItem {
    display: grid;
    column-gap: 20px;
    justify-content: end;
    background-color: #f0f0f0;
  }
  body #app #header-block menu.blockItem li {
    padding: 0 0.1em;
  }
  body #app #header-block menu.blockItem li a {
    padding: 0.5em;
  }
  body #app #header-block menu.blockItem li.phoneMob {
    display: none;
  }
  body #app #header-block .phoneBlock .pic.menu {
    display: none;
  }
}
@media only screen and (min-width: 1406px) {
  body #app #header-block {
    grid-template-areas: "S L M M M M M M M M P P P E";
  }
}
@media only screen and (min-width: 1696px) {
  body #app #header-block {
    grid-template-areas: "S L L M M M M M M M P P P E";
  }
  body #app #header-block .logoBlock .logo {
    font-size: 0.8em;
  }
  body #app #header-block menu.blockItem {
    justify-content: center;
  }
}
/*# sourceMappingURL=src/blocks/header.css.map */