
.vs-crumbs
    list-style:none
    margin:0
    padding:0
    display:inline-block;
    li
         display:inline-block
         .last
              cursor: default
