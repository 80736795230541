
@import "./assets/fonts/roboto/100/style.css"
@import "./assets/fonts/roboto/300/style.css"
@import "./assets/fonts/roboto/700/style.css"

.images010
    background-image: url('./assets/img/images/010.webp')
.images011
    background-image: url('./assets/img/images/011.webp')
.images012
    background-image: url('./assets/img/images/012.webp')
.images013
    background-image: url('./assets/img/images/013.webp')
.images014
    background-image: url('./assets/img/images/014.webp')
.images015
    background-image: url('./assets/img/images/015.webp')
.images001
    background-image: url('./assets/img/images/001.webp')
.images002
    background-image: url('./assets/img/images/002.webp')
.images003
    background-image: url('./assets/img/images/003.webp')
.images000
    background-image: url('./assets/img/images/000.webp')
.pictoL
    background-image: url('./assets/img/picto/l.svg')
.pictoB
    background-image: url('./assets/img/picto/b.svg')
.pictoK
    background-image: url('./assets/img/picto/k.svg')
.pictoR
    background-image: url('./assets/img/picto/r.svg')
.pictoP
    background-image: url('./assets/img/picto/p.svg')
.image-block
    background-size     contain
    width               33%
    padding-top         33%
    background-position center
    background-repeat   no-repeat
    display             inline-block
.image-block.block100
    width               100%
.center
    text-align          center
    text-align-last     center
html,body
    margin           0
    padding          0
    width            100%
    height           100%
    background-color #F0F0F0
    font-family:     'Roboto', sans-serif;
    color            #353535
    font-size        17px
.block
    padding          0
    margin           0
    width            100%
    min-height       3em
    display          grid
    column-gap       20px
    justify-content  center
.blockItem
    display          grid
    background-color yellow
a,a:active,a:visited
   text-decoration   none
   color             #353535

#app
    height           100%
    width            100%
    #content-block
        min-height 30em
body
    .block
        grid-template-columns: 0px repeat(12,1fr) 0px ;
        [item="3"],[item="4"],[item="5"],[item="6"],[item="7"],[item="8"],[item="9"],[item="10"],[item="11"],[item="12"]
            display grid

@media only screen and (min-width: 500px)
   body
       .block
          /* grid-template-columns: repeat(12,1fr);
           [item="3"]
               display grid/**/
@media only screen and (min-width: 768px)
   body
       .block
           /*grid-template-columns: repeat(12,1fr);
           [item="4"]
               display grid/**/
@media only screen and (min-width: 1024px)
   body
       .block
           grid-template-columns: 1fr repeat(12,60px) 1fr ;
           /*grid-template-columns: repeat(12,1fr);
           [item="5"],[item="6"]
               display grid/**/
@media only screen and (min-width: 1366px)
   body
       .block
           /*grid-template-columns: repeat(12,1fr);
           [item="7"],[item="8"]
               display grid/**/
@media only screen and (min-width: 1696px)
   body
       .block
           /*grid-template-columns: repeat(12,1fr);
           [item="9"],[item="10"]
               display grid/**/
.hide
    display none
