
iframe
    border: none;
    width: 100%;
    height: 50em;
.content
        grid-area H
#message
    text-align center
    table
         display: inline-table
         .label
            text-align right
         input
               display inline-block;
    .btn
         margin: 2.5em
         display: inline-block
         border: solid 1px #353535
         border-radius: 11px
         padding: 0.5em
         margin: auto
