#app #content-block {
  grid-template-areas: "O S S S S S S S S S S S S E " " O H H H H H H H H H H H H E ";
  align-content: start;
}
#app .content {
  grid-area: H;
}
#app .content #map {
  height: 20em;
  overflow: hidden;
}
/*# sourceMappingURL=src/views/home.css.map */