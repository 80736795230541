.btnC {
  color: #fff;
  background: #808080;
  padding: 0.1em 1em;
}
#app #content-block {
  align-content: start;
  grid-template-areas: "O S S S S S S S S S S S S E " " O H H H H H H H H H H H H E ";
}
#app .content {
  grid-area: H;
}
#app .content .btn {
  margin: 2em auto;
  display: block;
  width: max-content;
  background: #999;
  padding: 1em 2em;
}
#app .content .image-block.images003.block100.h100 {
  padding-top: 70%;
}
#app .content table {
  margin: auto;
}
#app .content th {
  text-align: left;
  background-size: contain;
  background-repeat: no-repeat;
  padding-left: 4em;
  height: 3em;
}
#app .content td {
  padding-left: 0.5em;
}
#app .content td+td {
  padding-left: 0.5em;
}
#app .content td+td+td {
  padding-left: 1em;
}
@media only screen and (min-width: 768px) {
  #app .content td {
    padding-left: 2em;
  }
  #app .content td+td {
    padding-left: 2em;
  }
  #app .content td+td+td {
    padding-left: 5em;
  }
}
/*# sourceMappingURL=src/views/produckt.css.map */