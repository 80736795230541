.vs-crumbs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}
.vs-crumbs li {
  display: inline-block;
}
.vs-crumbs li .last {
  cursor: default;
}
/*# sourceMappingURL=src/components/breadcrumbs.css.map */