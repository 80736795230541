
.btnC
    color: white;
    background: gray;
    padding: 0.1em 1em;
#app
    #content-block
        align-content: start;
        grid-template-areas "O S S S S S S S S S S S S E " " O H H H H H H H H H H H H E ";
    .content
        grid-area H
        .btn
            margin 2em auto
            display: block;
            width: max-content;
            background: #999;
            padding: 1em 2em;
        .image-block.images003.block100.h100
            padding-top 70%
        table
           margin auto
        th
            text-align left
            background-size: contain;
            background-repeat: no-repeat;
            padding-left: 4em;
            height: 3em;
        td
            padding-left 0.5em
            &+td
               padding-left 0.5em
               &+td
                   padding-left 1em
@media only screen and (min-width: 768px)
  #app
    .content
        td
            padding-left 2em
            &+td
               padding-left 2em
               &+td
                   padding-left 5em
