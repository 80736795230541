.sliderb000 {
  background-image: url("../assets/img/slider/b000.webp");
}
.sliderb001 {
  background-image: url("../assets/img/slider/b001.webp");
}
.sliderb002 {
  background-image: url("../assets/img/slider/b002.webp");
}
.sliderb003 {
  background-image: url("../assets/img/slider/b003.webp");
}
.sliderk000 {
  background-image: url("../assets/img/slider/k000.webp");
}
.sliderk001 {
  background-image: url("../assets/img/slider/k001.webp");
}
.sliderk002 {
  background-image: url("../assets/img/slider/k002.webp");
}
.sliderk003 {
  background-image: url("../assets/img/slider/k003.webp");
}
.sliderl000 {
  background-image: url("../assets/img/slider/l000.webp");
}
.sliderl001 {
  background-image: url("../assets/img/slider/l001.webp");
}
.sliderl002 {
  background-image: url("../assets/img/slider/l002.webp");
}
.sliderp000 {
  background-image: url("../assets/img/slider/p000.webp");
}
.sliderp001 {
  background-image: url("../assets/img/slider/p001.webp");
}
.sliderp002 {
  background-image: url("../assets/img/slider/p002.webp");
}
.sliderp003 {
  background-image: url("../assets/img/slider/p003.webp");
}
.sliderp004 {
  background-image: url("../assets/img/slider/p004.webp");
}
.sliderp005 {
  background-image: url("../assets/img/slider/p005.webp");
}
.sliderr000 {
  background-image: url("../assets/img/slider/r000.webp");
}
.sliderr001 {
  background-image: url("../assets/img/slider/r001.webp");
}
#slider {
  height: 20em;
  text-align-last: center;
  grid-area: S;
}
#slider .post input {
  display: none;
}
#slider .post input +a {
  display: none;
  width: 100%;
}
#slider .post input:checked +a {
  display: inline-block;
}
#slider .posts {
  text-align-last: center;
  position: relative;
  z-index: 100;
}
#slider .posts input {
  display: inline;
}
#slider .postText {
  display: inline-block;
  text-align-last: center;
  background-color: rgba(240,240,240,0.541);
  margin: 0;
  padding: 1em;
  position: absolute;
  width: 100% /*20em;/**/;
  bottom: 1em;
  top: 1em;
  right: 0;
  box-shadow: 0px 0px 5px 0px #76787b;
}
#slider .btnCall {
  margin: 1em auto;
  display: block;
  width: max-content;
  background: #999;
  padding: 1em 2em;
}
#slider .btn {
  height: 1em;
  padding: 7em 0;
  margin: 2em 0;
  width: 3em;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  background-size: 50%;
  opacity: 1;
  background-color: none;
  color: #353535;
  border: solid 1px #353535;
  position: relative;
  z-index: 100;
}
#slider .btn.right {
/*background-image: url('../assets/img/picto/right-black.svg')/**/
  float: right;
}
#slider .btn.left {
/*background-image: url('../assets/img/picto/left-black.svg')/**/
  float: left;
}
#slider .btn:hover {
  box-shadow: 0px 0px 5px 0px #76787b;
  opacity: 0.6;
}
#slider .post {
  display: inline;
  text-align-last: center;
}
#slider .post a {
  margin: 0;
  padding: 0;
  height: calc(20em + 2px);
  width: 100%;
  background-position: 50% 20%;
  background-size: cover;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  z-index: 10;
}
#slider .post h3 {
  text-align: center;
  padding: 0.5em;
}
#slider .post p a {
  position: relative;
}
@media only screen and (min-width: 768px) {
  #slider .postText {
    width: 20em;
    right: calc((100% - 12 * 60px) / 2);
  }
}
/*# sourceMappingURL=src/components/slider.css.map */