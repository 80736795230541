#app #footer-block {
  min-height: 15em;
  background-color: #f0f0f0;
  color: #cdad6b;
  margin-top: 20em;
  grid-template-areas: "L L L L L L L L L L L L L L";
}
#app #footer-block .blockItem {
  grid-area: L;
  background-size: 11em;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-color: #353535;
/*background-image      url("../assets/img/logo.svg")/**/
}
@media only screen and (min-width: 768px) {
  #app #footer-block h1 {
    font-size: 1.2em;
  }
}
/*# sourceMappingURL=src/blocks/footer.css.map */